"use client";
import { useSession } from "next-auth/react";
import { redirect, usePathname } from "next/navigation";
import React, { createContext } from "react";
import NotFound from "@app/not-found";
import {
  AccountSettingsPermissionCheck,
  AdminPermissionCheck,
  AssetWatchlistPermissionCheck,
  DashboardPermissionCheck,
  EventsPermissionCheck,
  FollowUpPermissionCheck,
  HomePagePermissionCheck,
  PermissionCheck
} from "./routePermissionChecks";

const UserPermissionContext = createContext(
  {} as {
    userCompanyGroup: string[] | undefined;
    isSuperuser: boolean;
    username: string | null | undefined;
    userId: string | undefined;
    accessToken: string | undefined;
    email: string | null | undefined;
    userGroups: string[] | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
  }
);

// Mapping URLs to permission check classes
// The first matching URL will be used
// The order of the mappings is important

// prettier-ignore
const permissionMappings: [string, PermissionCheck][] = [
  ["/dashboard/assets", new AssetWatchlistPermissionCheck()],
  ["/dashboard/settings", new AccountSettingsPermissionCheck()],
  ["/dashboard/events", new EventsPermissionCheck()],
  ["/dashboard/followup", new FollowUpPermissionCheck()],
  ["/dashboard/admin", new AdminPermissionCheck()],
  ["/dashboard", new DashboardPermissionCheck()],
  ["/", new HomePagePermissionCheck()],
];

// Provider component to manage user permissions
export function UserPermissionProvider({ children }: { children: React.ReactNode }) {
  const { data: session } = useSession();

  const pathname = usePathname();
  const checkPermission = () => {
    const matchingPermissionCheck = permissionMappings.find(([urlPattern]) => {
      const regex = new RegExp(urlPattern);
      return regex.test(pathname);
    });

    if (matchingPermissionCheck) {
      const [, permissionCheckInstance] = matchingPermissionCheck;
      return permissionCheckInstance.check({ session });
    }

    return false;
  };

  const isSuperuser = session?.user?.groups?.includes("user:superuser") ?? false;
  const accessToken = session?.accessToken;

  /* prettier-ignore */
  const userCompanyGroup = isSuperuser ? ["company:dev"] : session?.user?.groups?.filter((group) => group.startsWith("company:"));
  const userGroups = session?.user?.groups;

  const username = session?.user?.name;
  const userId = session?.sub;
  const email = session?.user?.email;

  const firstName = session?.user?.first_name;
  const lastName = session?.user?.last_name;

  if (checkPermission()) {
    return (
      <UserPermissionContext.Provider
        value={{
          userCompanyGroup,
          isSuperuser,
          username,
          userId,
          accessToken,
          email,
          userGroups,
          firstName,
          lastName
        }}
      >
        {children}
      </UserPermissionContext.Provider>
    );
  } else if (!session || session.error) {
    redirect(`/login${pathname && pathname !== "/login" ? `?callbackUrl=${pathname}` : ``}`);
  } else {
    return <NotFound />;
  }
}
export default UserPermissionContext;
