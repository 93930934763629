import type { Session } from "next-auth";

class PermissionCheck {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  check({ session }) {
    throw new Error(`Subclass must implement the 'check()' method: ${this.constructor.name}`);
  }
}

class DashboardPermissionCheck extends PermissionCheck {
  check() {
    // setting true so the existing check is not broken
    return true;
  }
}

class HomePagePermissionCheck extends PermissionCheck {
  check() {
    // setting true so the existing check is not broken
    return true;
  }
}

class AccountSettingsPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");

    if (isSuperuser || session?.user?.groups?.includes("user:account_settings")) {
      return true;
    }
    return false;
  }
}

class EventsPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");

    if (isSuperuser || session?.user?.groups?.includes("user:headlines")) {
      return true;
    }
    return false;
  }
}

class FollowUpPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");

    if (isSuperuser || session?.user?.groups?.includes("user:followup")) {
      return true;
    }
    return false;
  }
}

class AssetWatchlistPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");
    /* prettier-ignore */
    const userCompanyGroup = isSuperuser ? ["company:dev"] : session?.user?.groups?.filter((group) => group.startsWith("company:"));

    if (!session?.user?.groups) {
      return false;
    }
    if (
      (session?.user?.groups?.includes("user:watchlist") || isSuperuser) &&
      userCompanyGroup?.length
    ) {
      return true;
    }
    return false;
  }
}

class AdminPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");

    if (isSuperuser) {
      return true;
    }
    return false;
  }
}

export {
  AccountSettingsPermissionCheck,
  AdminPermissionCheck,
  AssetWatchlistPermissionCheck,
  DashboardPermissionCheck,
  EventsPermissionCheck,
  FollowUpPermissionCheck,
  HomePagePermissionCheck,
  PermissionCheck
};
